import h from 'hyperscript';

const replaceWithData = ($el, $replaceString, data) => {
  if (data && $el) {
    const HTML = $el.innerHTML.replaceAll($replaceString, data);
    $el.innerHTML = HTML;
  } else if ($el) {
    $el.remove();
  }
};

const resolveTemplate = (data) => {
  const $template = document.querySelector('.person-box__detail').innerHTML;
  const $templateWrapper = h('div');

  $templateWrapper.innerHTML = $template || '';

  const $name = $templateWrapper.querySelector('.js-name');
  const $role = $templateWrapper.querySelector('.js-role');
  const $slogan = $templateWrapper.querySelector('.js-slogan');

  const $image = $templateWrapper.querySelector('.js-image');

  const $phone = $templateWrapper.querySelector('.js-phone');
  const $mail = $templateWrapper.querySelector('.js-mail');

  const $xing = $templateWrapper.querySelector('.js-xing');
  const $facebook = $templateWrapper.querySelector('.js-facebook');
  const $twitter = $templateWrapper.querySelector('.js-twitter');
  const $linkedin = $templateWrapper.querySelector('.js-linkedin');
  const $googleplus = $templateWrapper.querySelector('.js-googleplus');
  const $xcom = $templateWrapper.querySelector('.js-xcom');

  const $sections = $templateWrapper.querySelector('.js-sections');
  const $section = $templateWrapper.querySelector('.js-section');
  const $sectionTitle = $templateWrapper.querySelector('.js-section-title');
  const $sectionText = $templateWrapper.querySelector('.js-text');
  const $sectionLink = $templateWrapper.querySelector('.js-link');
  const $sectionLinkExternal = $templateWrapper.querySelector('.js-link-external');

  const socialMedia = data.socialMedia || null;

  const $downloads = $templateWrapper.querySelector('.js-downloads');
  const $downloadsTitle = $templateWrapper.querySelector('.js-downloads-title');
  const $downloadsLink = $templateWrapper.querySelector('.js-downloads-link');

  // Prepare all single elements
  replaceWithData($name, '%NAME%', data.name || null);
  replaceWithData($role, '%POSITION%', data.role || null);
  replaceWithData($slogan, '%SLOGAN%', data.slogan || null);
  replaceWithData($phone, '%PHONE%', data.telephone || null);
  replaceWithData($mail, '%CONTACT%', data.contact || null);

  // Prepare social media elements
  replaceWithData($xing, '%XING%', socialMedia && (socialMedia.xing || null));
  replaceWithData($facebook, '%FACEBOOK%', socialMedia && (socialMedia.facebook || null));
  replaceWithData($twitter, '%TWITTER%', socialMedia && (socialMedia.twitter || null));
  replaceWithData($linkedin, '%LINKEDIN%', socialMedia && (socialMedia.linkedin || null));
  replaceWithData($googleplus, '%GOOGLEPLUS%', socialMedia && (socialMedia.googleplus || null));
  replaceWithData($xcom, '%XCOM%', socialMedia && (socialMedia.xcom || null));

  // Prepare image
  if (data.imageSrc) {
    $image.innerHTML = `<img src="${data.imageSrc}" width="${data.imageWidth}" height="${data.imageHeight}" class="person-detail__img"/>`;
  }

  // Prepare downloads
  $downloads.innerHTML = '';

  if (data.downloads) {
    // Prepare downloads title
    if (data.downloads.title) {
      const $downloadsTitleNew = $downloadsTitle.outerHTML
        .replaceAll('%DOWNLOADS_TITLE%', data.downloads.title);

      $downloads.innerHTML += $downloadsTitleNew;
    } else {
      $downloadsTitle.remove();
    }

    // Prepare section items
    data.downloads.items.forEach((item) => {
      if (item.link) {
        const $link = $downloadsLink;
        const $downloadLinkNew = $link.outerHTML
          .replaceAll('%DOWNLOADS_TEXT%', item.text)
          .replaceAll('%DOWNLOADS_LINK%', item.link);

        $downloads.innerHTML += $downloadLinkNew;
      }
    });
  }

  // Prepare sections
  $sections.innerHTML = '';

  if (data.sections) {
    data.sections.forEach((section) => {
      const $sectionClone = $section.cloneNode(true);
      $sectionClone.innerHTML = '';

      // Prepare section title
      if (section.title) {
        const $sectionTitleNew = $sectionTitle.outerHTML
          .replaceAll('%SECTION_TITLE%', section.title);

        $sectionClone.innerHTML += $sectionTitleNew;
      } else {
        $sectionTitle.remove();
      }

      // Prepare section items
      section.items.forEach((item) => {
        if (item.link) {
          const $link = item.external === true ? $sectionLinkExternal : $sectionLink;
          const $sectionLinkNew = $link.outerHTML
            .replaceAll('%ITEM_TEXT%', item.text)
            .replaceAll('%ITEM_LINK%', item.link);

          $sectionClone.innerHTML += $sectionLinkNew;
        } else {
          const $sectionTextNew = $sectionText.outerHTML
            .replaceAll('%ITEM_TEXT%', item.text);

          $sectionClone.innerHTML += $sectionTextNew;
        }
      });

      $sections.appendChild($sectionClone);
    });
  } else {
    $sections.remove();
  }

  return $templateWrapper;
};

export default resolveTemplate;
